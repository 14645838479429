import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ButtonGroup } from "shards-react";

export default class AboutModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const { open } = this.state;
        return (
            <ButtonGroup size="lg" className="mr-2">
                <Button theme="warning" onClick={this.toggle}>WTF is all this?</Button>
                <Modal centered={true} open={open} toggle={this.toggle}>
                    <ModalHeader>WTF is Minted by AI?</ModalHeader>
                    <ModalBody>
                        👋 Welcome to Minted by AI (MAI)!
                        <p>This site allows you to purchase unique digital art as a Non-Fungable Token (NFT).</p>
                        <p>MAI is a completely unique NFT which can be traded, sold or displayed and cherished.
                            When you mint, a random selection of traits will be imposed onto an
                            image to create a novel, 1-of-a-kind short video as displayed below. Each video is
                            created using MAI which is a neural net that was trained to create bad-ass artworks
                            in a variety of styles! Each frame in the video represents a step transformation from
                            the previous frame through a neural network. Through each pass, MAI imposes it’s learned
                            filters, intensifying the style and output.</p>
                        <p>
                            The benefits of holding a GENESIS MAI is that you can participate as a co-creator through
                            the custom attribute available in each MAI mint. This gives you the power to influence the
                            project forever. If you hold a GENESIS MAI you will receive early access to future mints and
                            affiliated projects, as well as many more magical MAI moments being developed right now.
                        </p>
                    </ModalBody>
                </Modal>
            </ButtonGroup>
        );
    }
}