import React, { useState } from 'react';
import { ethers } from 'ethers'
import MAI from '../artifacts/contracts/MAI.sol/MAI.json'
import AboutModel from './AboutModel';


import {
    Button,
    ButtonGroup,

} from "shards-react";
import "shards-ui/dist/css/shards.min.css"

const MAIAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"

const withCrypto = Component => props => {

    const [ownedTokensJson, setOwnedTokensJson] = useState([])
    const [gifUri, setGifUri] = useState("")
    const [nftAttr, setNftAttr] = useState("")
    const [userAccount, setUserAccount] = useState("")
    const [currentAccount, setCurrentAccount] = useState("Connect Wallet")
    const [amount, setAmount] = useState()
    const [checkTokenNumber, setCheckTokenNumber] = useState()
    const [loadVids, setLoadVids] = useState(false)

    // request access to the user's MetaMask account
    async function requestAccount() {
        const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
        setCurrentAccount(account.slice(0, 6) + " ... " + account.slice(account.length - 5, account.length))
    }

    // call the smart contract, read the current greeting value
    async function fetchTokens() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const contract = new ethers.Contract(MAIAddress, MAI.abi, provider)
            try {
                const attributes = await contract.getAttributes()
                const imageUri = await contract.getUri()
                console.log('attributes: ', attributes)
                console.log('imageUri: ', imageUri)
                setGifUri(imageUri)
                setNftAttr(attributes)
                //const id = await contract.getId()
                //const uri = await contract.getUri()

                //console.log('id: ', id)
                //console.log('uri: ', uri)
            } catch (err) {
                console.log("Error: ", err)
            }
        }
    }


    async function getWalletTokens() {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(MAIAddress, MAI.abi, provider)
            const balance = await contract.balanceOf(account);
            console.log("Wallet: " + account + " owns " + balance)
            if (balance > 0) {
                var counter = 0
                var ids = []
                while (true) {
                    try {
                        console.log("Checking token:" + counter);
                        const owner = await contract.ownerOf(counter);
                        console.log(owner + " == " + account);

                        if (owner.toLowerCase() === account.toLowerCase())
                            ids.push(counter)
                        console.log("owner of " + counter + ": ", owner.toString());
                    } catch (err) {
                        console.log("no more tokens")
                        break
                    }
                    counter++
                    if (counter > contract.maxSupply)
                        break;
                }

                console.log("Token uris: ", ids.length)

                let newOwnedtokensJson = []
                for (var i = 0; i < ids.length; i++) {
                    var uriJson = await contract.tokenURI(ids[i])
                    console.log("Fetching: ", uriJson)
                    let response = await fetch(uriJson + ".json");
                    if (response.ok) { // if HTTP-status is 200-299
                        let json = await response.json();
                        newOwnedtokensJson.push(json)
                    }
                }
                setOwnedTokensJson(newOwnedtokensJson)
            } else {
                setOwnedTokensJson([])
            }
        }
    }

    async function getOwnerById() {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(MAIAddress, MAI.abi, provider)
            //.retrieve()).toString()
            contract.ownerOf(checkTokenNumber)
                .then(owner => {
                    console.log("owner of " + checkTokenNumber + ": ", owner.toString());
                })
                .catch(e => {
                    console.error(e)
                });

            //console.log("owner of " + checkTokenNumber + ": ", owner.toString());
        }
    }

    async function sendCoins() {
        if (typeof window.ethereum !== 'undefined') {
            await requestAccount()
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(MAIAddress, MAI.abi, signer);
            const transation = await contract.transfer(userAccount, amount);
            await transation.wait();
            console.log(`${amount} Coins successfully sent to ${userAccount}`);
        }
    }

    async function send_token(
        contract_address,
        send_token_amount,
        to_address,
        send_account,
        private_key
    ) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        let gas_limit = "0x100000"

        let wallet = new ethers.Wallet(private_key)
        let walletSigner = wallet.connect(provider)

        provider.getGasPrice().then((currentGasPrice) => {
            let gas_price = ethers.utils.hexlify(parseInt(currentGasPrice))
            console.log(`gas_price: ${gas_price}`)

            const tx = {
                from: send_account,
                to: to_address,
                value: ethers.utils.parseEther(send_token_amount),
                nonce: provider.getTransactionCount(
                    send_account,
                    "latest"
                ),
                gasLimit: ethers.utils.hexlify(gas_limit), // 100000
                gasPrice: gas_price,
            }
            console.dir(tx)
            try {
                walletSigner.sendTransaction(tx).then((transaction) => {
                    console.dir(transaction)
                    alert("Send finished!")
                })
            } catch (error) {
                alert("failed to send!!")
            }

        })
    }


    async function createToken(number) {
        if (typeof window.ethereum !== 'undefined') {

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            let private_key = "59c6995e998f97a5a0044966f0945389dc9e86dae88c7a8412f4603b6b78690d"
            let send_token_amount = "1"
            let to_address = "0x70997970C51812dc3A010C7d01b50e0d17dc79C8"
            let send_address = currentAccount
            let gas_limit = "0x100000"

            let contract_address = ""
            await send_token(
                contract_address,
                send_token_amount,
                to_address,
                send_address,
                private_key
            )

            const contract = new ethers.Contract(MAIAddress, MAI.abi, signer);
            // fetch create token uri
            //const tokenUri = "https://mai-videos.s3.ap-southeast-2.amazonaws.com/mai"
            await contract.mint(number)
                .then('receipt', (receipt) => {
                    console.log("receipt: ", receipt);
                })
                .catch(e => {
                    console.error(e)
                });
            await contract.withdraw()

        }

    }




    // var userTokenCards = ownedTokensJson.map(tokenJson => {
    //     loadHiddenVideosUrl(tokenJson.name, tokenJson.image)
    // })


    return (
        <>
            <Component {...props} />
            <div style={{
                position: 'fixed',
                top: 60,
                left: 0,
                width: '100vw',
                textAlign: 'center',
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100
            }}>
                <Button disabled
                    style={{
                        position: 'fixed',
                        right: 2,
                    }}
                    onClick={requestAccount}>{currentAccount}</Button >
            </div>
            <div class="Action-button-group" style={{
                position: 'fixed',
                bottom: 40,
                left: 0,
                width: '100%',
                textAlign: 'center',
                alignItems: "center",
                justifyContent: "center",
            }}>
                {/* <ButtonGroup size="lg" className="mr-2">
                    <Button onClick={getWalletTokens}>My Gallery</Button >
                    <Button theme="secondary" onClick={() => setOwnedTokensJson([])}>MAI Demo Gallery</Button >
                </ButtonGroup> */}
                <ButtonGroup size="lg" className="mr-2">
                    <Button theme="success" disabled onClick={() => createToken(1)}>Mint 1 (40FTM)</Button >
                    <Button outline theme="success" disabled onClick={() => createToken(2)}>X2</Button >
                    <Button outline theme="success" disabled onClick={() => createToken(5)}>X5</Button >
                </ButtonGroup>


                <AboutModel />

            </div>
        </>
    );
};
export default withCrypto;