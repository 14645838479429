import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Collapse,
} from "shards-react";

export default class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            dropdownOpen: false,
            collapseOpen: false
        };
    }

    toggleDropdown() {
        this.setState({
            ...this.state,
            ...{
                dropdownOpen: !this.state.dropdownOpen
            }
        });
    }

    toggleNavbar() {
        this.setState({
            ...this.state,
            ...{
                collapseOpen: !this.state.collapseOpen
            }
        });
    }

    render() {
        return (
            <Navbar type="dark" theme="dark" style={{backgroundColor:"transparent" }} expand="md" fixed="top" sticky="top">
                <NavbarBrand><img src="logoText.png" alt="Minted by AI" style={{height:"30px"}}/></NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} />

                <Collapse open={this.state.collapseOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <NavLink active href="home">
                                Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink disabled href="#">
                                Mating
                            </NavLink>
                        </NavItem>
  
                    </Nav>

                </Collapse>
            </Navbar>
        );
    }
}