import './App.css';
import React from "react";
import "shards-ui/dist/css/shards.min.css"
import NavBar from './components/Navbar';
import background from "./media/background.jpg";
import ThreeViewer from "./components/ThreeViewer"




// Update with the contract address logged out to the CLI when it was deployed 
//const MAIAddress = "0x5fbdb2315678afecb367f032d93f642f64180aa3"
function App() {
  // store greeting in local state
 
  // var userTokenCards;
  // if (ownedTokensJson.length > 0) {
  //   userTokenCards = ownedTokensJson.map(tokenJson => {
  //     console.log(tokenJson.name)
  //     console.log(tokenJson.attributes)
  //     return (
  //       <Card style={{ maxWidth: "400px" }} className="Card">
  //         <CardHeader><h3>{tokenJson.name}</h3> Minted By AI</CardHeader>
  //         {tokenJson.image.length > 0 &&
  //           <ReactPlayer
  //             url={tokenJson.image}
  //             width='100%'
  //             maxHeight='600px'
  //             controls={false}
  //             loop={true}
  //             playing={true}
  //             playbackRate={0.5}
  //           />
  //         }
  //         <CardBody>
  //           <CardTitle>Attributes</CardTitle>
  //           <ListGroup >
  //             {tokenJson.attributes.map((att) => {
  //               return (
  //                 <ListGroupItem>{att}</ListGroupItem>
  //               )
  //             })}
  //           </ListGroup>
  //         </CardBody>
  //         <CardFooter >Ultra Rare</CardFooter>
  //       </Card>
  //     )
  //   })
  // }

  return (
    <div>
      <NavBar />
      <div class="App-body" style={{ backgroundImage: `url(${background})` }}>
        <ThreeViewer numDemoVideos={8} loadDemoVids={true} />
      </div>
    </div>
  );
}

export default App;