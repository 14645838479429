
export function getTokenMetaDataByIdOld(tokenId) {
    console.log("getTokenMetaDataById", tokenId)
    return fetch("https://mai-videos.s3.ap-southeast-2.amazonaws.com/mai" + tokenId + ".json")
}

export async function getTokenMetaDataById(idArr) {
    return new Promise(async (resolve) => {
        var data = []
        for (let i = 0; i < idArr.length; i++) {
            let tokenId = idArr[i]
            if (tokenId == undefined) {
                console.warn("tokenId is undefined");
                continue;
            }else{
                console.log("getTokenMetaDataById", tokenId);
            }
            tokenId = tokenId.replace("video","")
            let res = await fetch("https://mai-videos.s3.ap-southeast-2.amazonaws.com/mai" + tokenId + ".json")
            let json = await res.json()
            data.push(json)
        }
        resolve(data)
    })
}