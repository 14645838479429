import React, { useCallback, useMemo, useEffect, useState, Suspense, useRef } from "react";
import {
    Canvas,
    useFrame,
    extend,
    useThree,

} from '@react-three/fiber'
import ReactPlayer from 'react-player'

import * as THREE from "three";
//import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import {
    OrbitControls, 
    //useFBX,
     Stars, 
     //useTexture
} from '@react-three/drei'
import { CubeTextureLoader } from "three";
import { getTokenMetaDataById } from "../services/NFTData";

import withCrypto from "./withCrypto"

//https://codesandbox.io/embed/troika-3d-text-via-react-three-fiber-ntfx2?fontsize=14
import { Text } from "troika-three-text";
import fonts from "../fonts/fonts";

extend({ Text });

function Swarm({ count, mouse }) {
    const mesh = useRef()
    const light = useRef()
    const { size, viewport } = useThree()
    const aspect = size.width / viewport.width

    const dummy = useMemo(() => new THREE.Object3D(), [])
    // Generate some random positions, speed factors and timings
    const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < count; i++) {
            const t = Math.random() * 100
            const factor = 20 + Math.random() * 100
            const speed = 0.001 + Math.random() / 200
            const xFactor = -50 + Math.random() * 100
            const yFactor = -50 + Math.random() * 100
            const zFactor = -50 + Math.random() * 100
            temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
        }
        return temp
    }, [count])
    // The innards of this hook will run every frame
    useFrame(state => {
        // Makes the light follow the mouse
        light.current.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 0)
        // Run through the randomized data to calculate some movement
        particles.forEach((particle, i) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle
            // There is no sense or reason to any of this, just messing around with trigonometric functions
            t = particle.t += speed / 2
            const a = Math.cos(t) + Math.sin(t * 1) / 10
            const b = Math.sin(t) + Math.cos(t * 2) / 10
            const s = Math.cos(t)
            particle.mx += (mouse.current[0] - particle.mx) * 0.01
            particle.my += (mouse.current[1] * -1 - particle.my) * 0.01
            // Update the dummy object
            dummy.position.set(
                (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
                (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
                (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
            )
            dummy.scale.set(s, s, s)
            dummy.rotation.set(s * 5, s * 5, s * 5)
            dummy.updateMatrix()
            // And apply the matrix to the instanced item
            mesh.current.setMatrixAt(i, dummy.matrix)
        })
        mesh.current.instanceMatrix.needsUpdate = true
    })
    return (
        <>
            <pointLight ref={light} distance={10} intensity={100} color="purple" />
            <instancedMesh ref={mesh} args={[null, null, count]}>
                <dodecahedronBufferGeometry attach="geometry" args={[0.1, 0]} />
                <meshPhongMaterial attach="material" color="purple" />
            </instancedMesh>
        </>
    )
}

// Loads the skybox texture and applies it to the scene.
function SkyBox() {
    const { scene } = useThree();
    const loader = new CubeTextureLoader();
    // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
    const texture = loader.load([
        "skybox/ulukai/corona_lf.png",
        "skybox/ulukai/corona_rt.png",
        "skybox/ulukai/corona_up.png",
        "skybox/ulukai/corona_dn.png",
        "skybox/ulukai/corona_ft.png",
        "skybox/ulukai/corona_bk.png"
    ]);
    // Set the scene background property to the resulting texture.

    scene.background = texture;
    return <mesh
    >
        <boxGeometry args={[10000, 10000, 10000]} />
        <meshBasicMaterial map={texture} side={THREE.DoubleSide} toneMapped={false} />
    </mesh>
}


// function Room() {
//     //const fbx = useLoader(FBXLoader, '/SM_Wall_AA.fbx')
//     const fbx = useFBX('models\\future_corridor\\SM_Wall_AA.fbx')

//     const [colorMap, normalMap, roughnessMap] = useTexture([
//         'models\\future_corridor\\Textures\\SM_Wall_AA\\WallAA_Base_Color.png',
//         'models\\future_corridor\\Textures\\SM_Wall_AA\\WallAA_Normal.png',
//         'models\\future_corridor\\Textures\\SM_Wall_AA\\WallAA_Roughness.png',
//     ])

//     //let fbx = useFBX('public/models/future_corridor/SM_Wall_AA.fbx')
//     // wrap fbx in primitive.
//     return (
//         <mesh>
//             <primitive object={fbx} dispose={null} />
//             <meshStandardMaterial
//                 map={colorMap}
//                 normalMap={normalMap}
//                 roughnessMap={roughnessMap}
//             />
//         </mesh>
//     )
// }



function VideoViewer(props) {
    console.log("VideoViewer", props.vid.key)
    let vid = document.getElementById(props.vid.key).children[0]
    let tokenData = props.tokenData

    console.log("TokenData: ", tokenData)
    console.log("vid", vid);
    try {
        var videoTexture = new THREE.VideoTexture(vid);

    } catch (err) {
        console.log("not available");
    }


    // This reference will give us direct access to the THREE.Mesh object
    const ref = useRef()
    // Subscribe this component to the render-loop, rotate the mesh every frame
    var counter = 0;
    useFrame((state, delta) => {
        counter += 0.01
        ref.current.position.y = Math.sin(counter) * 0.05
        ref.current.lookAt(0, 0, 0);
    })
    // Return the view, these are regular Threejs elements expressed in JSX
    return (
        <>
            <mesh
                {...props}
                ref={ref}
            >
                <planeGeometry args={[1, 1, 1]} />
                <meshBasicMaterial map={videoTexture} side={THREE.DoubleSide} toneMapped={false} />

                {tokenData != undefined ? (
                    <>
                        <text
                            position-y={-0.65}
                            rotation-y={Math.PI / 180 * 180}
                            text={tokenData.name}
                            fontSize={0.1}
                            font={fonts["Philosopher"]}
                            anchorX="center"
                            anchorY="middle"
                            color="Red"
                        >
                        </text>

                        <text
                            position-y={-0.75}
                            rotation-y={Math.PI / 180 * 180}
                            text={tokenData.attributes.map((e) => e.toUpperCase()).join(" ")}
                            fontSize={0.05}
                            font={fonts["Philosopher"]}
                            anchorX="center"
                            anchorY="middle"
                            color="#99ccff"
                        >
                        </text>
                        <text
                            position-y={-0.85}
                            rotation-y={Math.PI / 180 * 180}
                            text="-Ultra Rare-"
                            fontSize={0.075}
                            font={fonts["Philosopher"]}
                            anchorX="center"
                            anchorY="middle"
                            color="Purple"
                        >
                        </text>
                        <text
                            position-y={0.65}
                            rotation-y={Math.PI / 180 * 180}
                            text="Genesis Mint"
                            fontSize={0.095}
                            font={fonts["Philosopher"]}
                            anchorX="center"
                            anchorY="middle"
                            color="Green"
                        >
                        </text>

                    </>
                ) : (
                    <text
                        position-y={-0.65}
                        rotation-y={Math.PI / 180 * 180}
                        text="Loading properties..."
                        fontSize={0.1}
                        font={fonts["Philosopher"]}
                        anchorX="center"
                        anchorY="middle"
                        color="#99ccff"
                    >
                    </text>
                )

                }
            </mesh>


        </>
    )
}

function createHiddenVideos(id) {
    return <ReactPlayer
        style={{ display: 'none' }}
        key={"video" + id}
        id={"video" + id}
        config={{
            file: {
                attributes: {
                    crossOrigin: 'true'
                }
            }
        }}
        url={"https://mai-videos.s3.ap-southeast-2.amazonaws.com/mai" + id + ".mp4"}
        minwidth='400px'
        width='400px'
        muted={true}
        controls={true}
        loop={true}
        playing={true}
        playbackRate={0.5}
    />
}


function ThreeGallery(props) {

    const [maiTokenData, setMaiTokenData] = useState([])
    const [hiddenVids, setHiddenVids] = useState([])
    const [demoVideos, setDemoVideos] = useState([])
    const [hiddenVidsRefs, setHiddenVidsRefs] = useState([])
    const mouse = useRef([0, 0])
    const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])

    useEffect(() => {
        //Create hidden videos if not existent
        var hiddenVidsTemp = []
        var hiddenVidsRefsTemp = []
        var op = [0, 1, 2, 3, 4, 5, 6, 7] //Video ID options for demo
        for (let i = 0; i < props.numDemoVideos; i++) {
            hiddenVidsTemp.push(createHiddenVideos(op.pop(Math.round(Math.random() * op.length - 1))))
            console.log("vids[i].key", hiddenVidsTemp[i].key)
        }
        console.log("Created hidden vids: ", hiddenVidsTemp.length)

        let vidKeys = hiddenVidsTemp.map(vid => vid.key)
        getTokenMetaDataById(vidKeys)
            .then(dataArr => {
                console.log("setting mai token data", dataArr)
                setMaiTokenData(dataArr)
            })
            .catch(err => console.error(err))

        setHiddenVids(hiddenVidsTemp)

    }, [])

    useEffect(() => {
        let r = 2.75
        var demoVideosTemp = []
        let angleOffset = 360 / hiddenVids.length;
        if (maiTokenData.length > 0 && maiTokenData.length == hiddenVids.length && demoVideos.length == 0) {
            console.log("----------\nhiddenVids.length", hiddenVids.length)
            console.log("----------\maiTokenData.length", maiTokenData.length)

            for (let i = 0; i < hiddenVids.length; i++) {
                let theta = angleOffset * i
                let z = r * Math.cos(Math.PI / 180 * theta)
                let x = r * Math.sin(Math.PI / 180 * theta)
                let tokenData = maiTokenData.length == hiddenVids.length ? maiTokenData[i] : null
                let vidRef = hiddenVidsRefs[i]
                console.log("token data: ", tokenData)
                console.log("hiddenVids[i]: ", hiddenVids[i])
                demoVideosTemp.push(
                    <VideoViewer
                        key={"videoViewer" + i}
                        position={[x, 0, z]}
                        vid={hiddenVids[i]}
                        tokenData={tokenData} />
                )
            }
            setDemoVideos(demoVideosTemp)
        }
    }, [maiTokenData,demoVideos,hiddenVids])


    console.log("Rendering three plane demo videos", demoVideos)

    return (
        <>
            {hiddenVids}
            <Canvas style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
            }}>
                <Suspense fallback={null}>
                    <Stars radius={200} depth={100} count={10000} factor={10} saturation={1} fade />
                    <Swarm count={1000} mouse={mouse} />
                    <OrbitControls
                        maxDistance={4.5}
                        autoRotate={true}
                        autoRotateSpeed={0.5}
                        //minAzimuthAngle={-Math.PI / 6}
                        // maxAzimuthAngle={Math.PI / 6}
                        minPolarAngle={Math.PI / 2 + (5 * Math.PI / 180)}
                        maxPolarAngle={Math.PI / 2 + (5 * Math.PI / 180)}
                        enablePan={false}
                        enableZoom={false}
                    />
                    <ambientLight />
                    <pointLight position={[10, 10, 10]} />


                    {demoVideos}
                    <SkyBox />
                </Suspense>

            </Canvas>
        </>
    )
}

export default React.memo(withCrypto(ThreeGallery));